import {FullMetadata} from "firebase/storage";

import {useState} from "react";

import {ProviderData} from "../../hooks/useProviders";
import {VendingMachinesData} from "../../hooks/useVendingMachines";

import ButtonInput from "../forms/inputs/ButtonInput";

import {ButtonGroup, Paper, Skeleton} from "@mui/material";
import {Delete, FileDownload, Print, Visibility} from "@mui/icons-material";

interface Props {
  loading: boolean;
  file: FullMetadata;
  latestGenerationProcess?: string;
  vendingMachines: VendingMachinesData;
  providers: ProviderData;
  isOldTime: number;
  onViewClick: (fullPath: string) => void;
  onDownloadClick: (fullPath: string) => void;
  onPrintClick: (fullPath: string) => void;
  onDeleteClick: (fullPath: string) => void;
}

const FileCard = ({ loading, file, latestGenerationProcess, vendingMachines, providers, isOldTime, onViewClick, onDownloadClick, onPrintClick, onDeleteClick }: Props) => {

  if(loading) {
    return <Skeleton variant="rectangular" className="file-container__file file-container__file--loading" width={300} height={396.4}/>
  }

  const [fileActionLoading, setFileActionLoading] = useState<number>(-1);

  const executeFileAction = async (index: number, actionVoid: (fullPath: string) => void): Promise<void> => {
    setFileActionLoading(index);
    await actionVoid(file.fullPath);
    setFileActionLoading(-1);
  };

  const getFileType = (file: any): string => {
    if(Date.now() - new Date(file.timeCreated).getTime() < isOldTime) {
      if(latestGenerationProcess === file.customMetadata.generationProcess) {
        return "file-container__file--new";
      }
      return "file-container__file--aged";
    }
    return "file-container__file--old";
  };

  const vendingMachinesUids = JSON.parse(file?.customMetadata!.vendingMachineUids);

  return (
    <Paper
      elevation={3}
      className={"file-container__file " + getFileType(file)}
    >
      <div className="medium-text">Befüllvorschlag</div>
      <div className="big-text">
        <span>Am</span> { new Date(file.timeCreated).toLocaleString("de-DE", {timeZone: "Europe/Vienna"}).split(",")[0] }
        <br/>
        <span className="medium-text">von </span>
        {
          vendingMachinesUids.length === 1 ?
            (vendingMachines[vendingMachinesUids[0]] ? vendingMachines[vendingMachinesUids[0]].name : vendingMachinesUids[0]) :
            "Verschiedenen"
        }
      </div>
      <div>
        <p>
          Automaten-Informationen: <br/>
          ID{ vendingMachinesUids.length > 1 && "s" }: { vendingMachinesUids.join(", ") } <br/>
          Anbieter: {
          vendingMachinesUids.map((uid: string) => {
            return vendingMachines[uid] ? providers[vendingMachines[uid].providerId].name : "unbekannt";
          }).filter((value: string, index: number, self: Array<string>) => self.indexOf(value) === index).join(", ")
        }
          <br/>
          Name{ vendingMachinesUids.length > 1 && "n" }: {
          vendingMachinesUids.map((uid: string) => {
            return vendingMachines[uid] ? vendingMachines[uid].name : "unbekannt";
          }).filter((value: string, index: number, self: Array<string>) => self.indexOf(value) === index).join(", ")
        }
          <br/>
        </p>
        <p>
          Datei-Informationen: <br/>
          ID: {file.name} <br/>
          Erstelldatum: {new Date(file.timeCreated).toLocaleString("de-DE", {timeZone: "Europe/Vienna"})} <br/>
          Speichergröße: {file.size / 1000} kB
        </p>

      </div>
      <div>
        <ButtonGroup variant="text">
          <ButtonInput
            actionLoading={fileActionLoading == 0}
            text={<Visibility />}
            endIcon={null}
            onClick={() => executeFileAction(0, onViewClick)}
          />
          <ButtonInput
            actionLoading={fileActionLoading == 1}
            text={<FileDownload />}
            endIcon={null}
            onClick={() => executeFileAction(1, onDownloadClick)}
          />
          <ButtonInput
            actionLoading={fileActionLoading == 2}
            text={<Print />}
            endIcon={null}
            onClick={() => executeFileAction(2, onPrintClick)}
          />
          <ButtonInput
            actionLoading={fileActionLoading == 3}
            text={<Delete />}
            endIcon={null}
            onClick={() => executeFileAction(3, onDeleteClick)}
          />
        </ButtonGroup>
      </div>
    </Paper>
  );
};

export default FileCard;
