export interface FormInfo {
  type?: boolean;
  message?: string;
  timeout?: number;
}

export interface FormOut<Data> {
  data: Data;
  check: () => Promise<any>;
  save?: (...params: any[]) => Promise<any>;
}

export const createInfo = (type?: boolean, message?: string, timeout?: number): FormInfo => {
  return {
    type: type,
    message: message,
    timeout: timeout || 0
  }
};

export const checkEmailInput = (email: string): RegExpMatchArray | null => {
  return String(email)
  .toLowerCase()
  .match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

export const checkPasswordInput = (password1: string, password2: string): void => {
  if (!password1 || !password2) {
    throw new Error("Nicht alle erforderlichen Felder wurden ausgefüllt.")
  } else if (password1.length < 6 || password2.length < 6) {
    throw new Error("Passwortkriterien wurden nicht erfüllt.");
  } else if (password1 != password2) {
    throw new Error("Passwörter stimment nicht überein.");
  }
};