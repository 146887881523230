import {useOnSnapshotCollection} from "./useDatabase";

export interface Group {
  name: string;
}

export interface GroupsData {
  [key: string]: Group;
}

export function useGroups(userId: string | undefined): GroupsData | undefined {
  return useOnSnapshotCollection(userId ? "users/" + userId + "/groups" : undefined, true) as GroupsData;
}