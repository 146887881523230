import { DocumentData } from "firebase/firestore";
import { User } from "firebase/auth";

import { ReactElement, useEffect } from "react";
import { useNavigate, useLocation, NavigateFunction, Location } from "react-router-dom";

import { useFirebaseUser } from "../../hooks/userHook";
import { useOnSnapshot } from "../../hooks/useDatabase";

import { Backdrop, CircularProgress } from "@mui/material";

interface Props {
  render: (loading: boolean, firebaseUser: User | null, user: DocumentData | null | undefined) => ReactElement<any>;
}

const AuthChecker = ({ render }: Props) => {

  const navigate: NavigateFunction = useNavigate();
  const location: Location = useLocation();

  const firebaseUser: User | null | undefined = useFirebaseUser();
  const user: DocumentData | null | undefined = useOnSnapshot("users", firebaseUser?.uid);

  const navigateTo = (path: string) => {
    if(location.pathname !== path) {
      navigate(path);
    }
  };

  useEffect(() => {
    if(firebaseUser === null) {
      navigateTo("/kickoff");
    }
  }, [firebaseUser]);

  useEffect(() => {
      if(user) {
        if(location.pathname === "/welcome") {
          navigateTo("/");
        }
      }
      else if (user !== undefined) {
        navigateTo("/welcome");
      }
  }, [user, location.pathname]);

  if(!firebaseUser) {
    return (
      <>
        <Backdrop
          className="backdrop-loading"
          open={true}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
      </>
    );
  }

  return (
    <>
      {
        render(
          (!firebaseUser || user === undefined),
          firebaseUser,
          user
        )
      }
    </>
  );
};

export default AuthChecker;
