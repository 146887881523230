import {FullMetadata} from "firebase/storage";

import {ProviderData} from "../../hooks/useProviders";
import {VendingMachinesData} from "../../hooks/useVendingMachines";

import FileCard from "./File";

import { Typography } from "@mui/material";

import "../../style/FileViewer.css";

export type FileInfoList = Array<FullMetadata>;

interface Props {
  loading: boolean;
  files: FileInfoList;
  vendingMachines?: VendingMachinesData;
  providers?: ProviderData;
  onViewClick: (fullPath: string) => void;
  onDownloadClick: (fullPath: string) => void;
  onPrintClick: (fullPath: string) => void;
  onDeleteClick: (fullPath: string) => void;
  isOldTime?: number;
}

const FileViewer = ({ loading, files, vendingMachines={}, providers={}, onViewClick, onDownloadClick, onPrintClick, onDeleteClick, isOldTime=(1000 * 60 * 60) }: Props) => {
  return (
    <div className="file-viewer-container">
      <div>
        <Typography variant="h6">Gespeicherte Befüllvorschläge</Typography>
      </div>
      <div className="file-container">
        <div className="dynamic-container dynamic-container--first">
          <div className="dynamic-container__heading">
            <div className="dynamic-container__reference-color dynamic-container__reference-color--new"></div>
            <div>Gerade generiert</div>
          </div>
          <div className="dynamic-container__heading">
            <div className="dynamic-container__reference-color dynamic-container__reference-color--aged"></div>
            <div>Vor kurzem generiert (max. { isOldTime / (1000 * 60) }min)</div>
          </div>
        </div>
        <div className="dynamic-container dynamic-container--second">
          <div className="dynamic-container__heading">
            <div className="dynamic-container__reference-color dynamic-container__reference-color--old"></div>
            <div>Ältere Befüllvorschläge</div>
          </div>
        </div>
        {files.map((file: any, index: number) => {
          return (
            <FileCard
              key={index}
              loading={loading}
              file={file}
              latestGenerationProcess={files[0]?.customMetadata?.generationProcess}
              vendingMachines={vendingMachines}
              providers={providers}
              isOldTime={isOldTime}
              onViewClick={onViewClick}
              onDownloadClick={onDownloadClick}
              onPrintClick={onPrintClick}
              onDeleteClick={onDeleteClick}
            />
          );
        })}
      </div>
    </div>
  );
};

export default FileViewer;
