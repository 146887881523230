import {Skeleton, Stepper, Step, StepLabel} from "@mui/material";

import "../style/ProfileHeader.css";

interface Props {
  loading?: boolean;
  steps: Array<string>;
  activeStep: number;
}

const StepperNavigation = ({ loading, steps, activeStep }: Props) => {

  if(loading) {
    return (
      <div className="stepper-container">
        <Skeleton variant="rounded" width={"100%"} height={24} />
      </div>
    );
  }

  return (
      <Stepper activeStep={activeStep} className="stepper-container">
          {steps.map((label: string) => {
              return (
                  <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                  </Step>
              );
          })}
      </Stepper>
  );
};

export default StepperNavigation;
