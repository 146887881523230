import {auth, db} from "../config/firebase";

import {doc, addDoc, updateDoc, setDoc, collection} from "firebase/firestore";
import { updatePassword, User } from "firebase/auth";

import {VendingMachinesData} from "../hooks/useVendingMachines";
import {LoginData} from "../hooks/useCredentials";

import {PersonalInformation} from "../components/forms/PersonalInformationForm";
import {SettingsData} from "../components/forms/SettingsForm";

const defaultUserSettings: SettingsData = {
  qrCode: {
    size: 200, // px
    complexity: 1000 // chars
  },
  checkoutSystem: "",
  groupManagement: false
};

export const addUser = async (
  authUser: User,
  password: string,
  personalInformationData: PersonalInformation,
  providerData: LoginData,
  vendingMachinesData: VendingMachinesData
): Promise<void> => {

  await updatePassword(authUser, password);

  await setDoc(doc(db, "users", authUser.uid), {...personalInformationData, settings: defaultUserSettings});

  await setUserMultiple(providerData, "credentials");
  await setUserMultiple(vendingMachinesData, "vendingMachines");
};

export const updateUser = (updateData: any): Promise<void> => {
  return updateDoc(doc(db, "users/" + auth.currentUser?.uid), updateData);
};

export const newDoc = (path: string, data: any) : Promise<any> => {
  return addDoc(collection(db, path), data);
};

export const updateUserMultiple = async (updateData: any, subCollection: string=""): Promise<void> => {
  let key: string;
  for(key in updateData) {
    await updateDoc(doc(db, "users/" + auth.currentUser?.uid+ "/" + subCollection + "/" + key), {...updateData[key]});
  }
};

export const setUserMultiple = async (updateData: any, subCollection: string=""): Promise<void> => {
  let key: string;
  for(key in updateData) {
    await setDoc(doc(db, "users/" + auth.currentUser?.uid + "/" + subCollection + "/" + key), {...updateData[key]});
  }
};
