import {auth} from "../config/firebase";

import { useDoc } from "../hooks/useDatabase";

import { Paper, Alert, AlertTitle } from "@mui/material";

import Navbar from "./Navbar";
import Footer from "./Footer";

import "../style/MainContainer.css";

interface Props {
    children: React.ReactNode;
  }

const MainContainer = ({children}: Props) => {
    let systemAlert = null;
    if(auth.currentUser) {
      systemAlert = useDoc("systemAlerts", "main");
    }

    return (
        <div className="page-container">
            <Navbar />
            <div className="page-container__inner">
                <Paper elevation={2} className="main-container">
                    {
                        systemAlert?.active && (
                            <Alert className="main-container__alert" severity={systemAlert?.type ? "info" : "warning"}>
                                <AlertTitle>{systemAlert?.headline}</AlertTitle>
                                {systemAlert.description}
                            </Alert>
                        )
                    }
                    {children} 
                </Paper>
            </div>
            <Footer />
        </div>
    );
};

export default MainContainer;
