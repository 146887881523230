import { Box, Typography, Alert, Stack } from "@mui/material";

import "../../../style/AuthForm.css"; 

interface Props {
  children: React.ReactNode;
  headline?: string;
  info?: any;
  showRequiredTag?: boolean;
  hideInfo?: boolean;
  footer?: any;
}

const AuthFormContainer = ({ children, headline, info, showRequiredTag=true, hideInfo=true, footer="" }: Props) => {
  return (
    <Stack spacing={3} className="auth-container">
      <Alert
        severity={info.type ? "success" : "error"}
        className="auth-container__alert"
        style={!hideInfo ? {display: info.message ? "" : "none"} : {visibility: info.message ? "visible" : "hidden"}}
      >
        { info.message }
      </Alert>
      <Box boxShadow={3} component="form" autoComplete="on" className="auth-container__form">
        <Stack spacing={3}>
          { headline && <Typography variant="h6">{ headline }</Typography> }
          { children }
          {showRequiredTag && <p><span className="small-text">* erforderliche Information</span></p>}
        </Stack>
      </Box>
      <div className="auth-container__footer">
        { footer }
      </div>
    </Stack>
  );
};

export default AuthFormContainer;
