import { useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";

import { auth } from "../config/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";

import { createInfo, FormInfo } from "../utils/formUtils";

import SplitScreen from "../components/SplitScreen";
import LogoBig from "../components/LogoBig";
import AuthFormContainer from "../components/forms/containers/AuthFormContainer";
import Footer from "../components/Footer";
import LoginForm, { LoginFormOut } from "../components/forms/LoginForm";

interface Props {
}

const Login = ({}: Props) => {

  const navigate: NavigateFunction = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);

  const [formInfo, setFormInfo] = useState<FormInfo>(createInfo());

  const signIn = async (check: () => Promise<void>, email: string, password: string) => {
    setLoading(true);
    try {
      await check();
      try {
        await signInWithEmailAndPassword(auth, email, password);
      }
      catch (error: any) {
        throw new Error("Benutzername oder Passwort falsch.");
      }
      setFormInfo(createInfo(true, "Anmeldung erfolgreich."));
      navigate("/");
    }
    catch (error: any) {
      setFormInfo(createInfo(false, error.message));
      setLoading(false);
    }
  };

  return (
    <>
    <SplitScreen>
      <LogoBig />
      <AuthFormContainer
        info={formInfo}
        showRequiredTag={false}
        footer={
          <>
            {/* Noch keinen Account? Erstellen Sie <Link to="/register" className="href-link">hier</Link> einen Account. */}
          </>
        }
      >
        <LoginForm
          onSubmit={(data: LoginFormOut) => signIn(data.check, data.data.email, data.data.password)}
          actionLoading={loading}
        />
      </AuthFormContainer>
    </SplitScreen>
    <Footer isOnLogin={true} />
    </>
  );
};

export default Login;
