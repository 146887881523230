import { useState } from "react";
import {Link} from "react-router-dom";

import { getAuth, sendPasswordResetEmail } from "firebase/auth";

import { createInfo, FormInfo } from "../utils/formUtils";

import SplitScreen from "../components/SplitScreen";
import LogoBig from "../components/LogoBig";
import AuthFormContainer from "../components/forms/containers/AuthFormContainer";
import Footer from "../components/Footer";
import SendRecoveryEmailForm, {ChangePasswordFormOut} from "../components/forms/SendRecoveryEmailForm";

interface Props {
}

const SendRecoveryEmail = ({}: Props) => {
  const [loading, setLoading] = useState<boolean>(false);

  const [formInfo, setFormInfo] = useState<FormInfo>(createInfo());

  const sendRecoverMail = async (check: () => Promise<void>, email: string): Promise<void> => {
    setLoading(true);
    try {
      await check();
      await sendPasswordResetEmail(getAuth(), email);
      setFormInfo(createInfo(true, "Wiederherstellungs-Email wurde erfolgreich gesendet."));
    }
    catch (error: any) {
      setFormInfo(createInfo(false, error.message));
    }
    finally {
      setLoading(false);
    }
  };

  return (
    <>
    <SplitScreen>
      <LogoBig />
      <AuthFormContainer
        headline="Passwort zurücksetzen"
        info={formInfo}
        showRequiredTag={false}
        footer={
          <>
            Passwort wieder eingefallen? Melden Sie sich <Link to="/login" className="href-link">hier</Link> an.
          </>
        }
      >
        <SendRecoveryEmailForm
          onSubmit={(data: ChangePasswordFormOut) => sendRecoverMail(data.check, data.data.email)}
          actionLoading={loading}
        />
      </AuthFormContainer>
    </SplitScreen>
    <Footer isOnLogin={true} />
    </>
  );
};

export default SendRecoveryEmail;
