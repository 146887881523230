import { Link } from "react-router-dom";

import MainContainer from "../components/MainContainer";
import LogoBig from "../components/LogoBig";

import { Typography } from "@mui/material";
import { Phone, Email, Login } from '@mui/icons-material';

import "../style/KickOff.css";

interface Props {}

const KickOff = ({}: Props) => {

  return (
    <MainContainer>
      <LogoBig toRow={true} />

      <div className="all-in-one-container">
        <Typography variant="h5">Alles auf einem Blick</Typography>
        <img className="kickoff__big-image" src="images/allOne.png" />
        <p className="kickoff__text-block">
          Venbob ruft alle benötigten Informationen sämtlicher Telemetrie-Anbieter ab, verarbeitet diese,
          und bereitet Ihnen anschließend alle Informationen benutzerfreundlich auf. Alle Daten sind zentral gesammelt und abrufbar.
        </p>
      </div>

      <div className="request-offer-container">
        <Typography variant="h5">Jetzt Angebot beantragen</Typography>
        <p className="kickoff__text-block">
          Wir erstellen jedem einzelnen Kunden individuell angepasste Angebote um die Nutzungsbedürfnisse so gut wie möglich abzudecken.
          Schreiben Sie uns ein E-Mail, oder rufen Sie uns auch gerne an. Wir freuen uns auf Sie!
        </p>
        <div className="request-offer-container__contact-information">
          <a className="hidden-href-link" href="mailto:office@venbob.com">
            <Email /> office@venbob.com
          </a>
          <a className="hidden-href-link" href="tel:00436504040537">
            <Phone /> +43 650 4040 537
          </a>
          <Link className="hidden-href-link" to="/login">
            <Login /> Login
          </Link>
        </div>
      </div>

      <div className="auto-billing-container">
        <Typography variant="h5">Automatisierte Abrechnung: Kein nerviges eintippen mehr</Typography>
        <img className="kickoff__big-image" src="images/automatedBilling.png" />
        <p className="kickoff__text-block">
          Venbob generiert zu jedem Befüllvorschlag der Automaten einen QR-Code.
          Einfach nachzufüllende Automaten auswählen, Befüllvorschlag drucken und QR-Code mit Ihrem Kassen-Lesegerät scannen.
          Nun werden innerhalb weniger Sekunden alle verkauften Artikel in Ihrem Kassensystem aufscheinen.
        </p>
      </div>

      <div className="supported-providers-container">
        <div className="supported-providers-container__headline">
          <Typography variant="h5">Unterstützte Anbieter</Typography>
        </div>
        <div className="supported-providers-container__provider-images">
          <img src="images/providers/tobaccoland.png" />
          <img src="images/providers/easyVending.png" />
          <img src="images/providers/garzFrickeVmdsWeb.png" />
        </div>
      </div>

      <div className="supported-providers-container">
        <div className="supported-providers-container__headline">
          <Typography variant="h5">Unterstützte Kassensysteme</Typography>
        </div>
        <div className="supported-providers-container__provider-images">
          <img src="images/checkoutSystems/cwl.png" />
          <img src="images/checkoutSystems/toptech.png" />
          <img src="images/checkoutSystems/postronik.png" />
        </div>
      </div>

    </MainContainer>
  );
};

export default KickOff;
