import { useState, useEffect } from "react";

import {DocumentData} from "firebase/firestore";

import {Box, Typography, Alert, LinearProgress} from "@mui/material";

import "../../../style/FormStyle.css";

interface Props {
  children: React.ReactNode;
  processInfo?: DocumentData | null;
  headline: string;
  description?: string;
  info?: any;
  show?: boolean;
  showRequiredTag?: boolean;
  hideInfo?: boolean;
  createForm?: boolean;
}

const FormContainer = ({ children, processInfo, headline, description, info, show=true, showRequiredTag=true, hideInfo=false, createForm=true }: Props) => {

  const [showInfo, setShowInfo] = useState<boolean>(true);

  useEffect(() => {
      setShowInfo(true);
      if(info.timeout) {
      const currentTimeout: NodeJS.Timeout = setTimeout(() => {
        setShowInfo(false);
      }, info.timeout);
      return () => {
        clearTimeout(currentTimeout);
      };
    }
  }, [info]);

  return (
    <div style={{display: show ? "" : "none"}}>
      <div
        className="feedback-container"
        style={!hideInfo ? {display: ((info.message && showInfo) || processInfo?.active) ? "" : "none"} : {visibility: ((info.message && showInfo) || processInfo?.active) ? "visible" : "hidden"}}
      >
        {
          processInfo?.active ? (
            <div className="progress-container">
              <div className="progress-container__bar">
                <LinearProgress
                  variant={processInfo.progress === -1 ? "indeterminate" : "determinate"}
                  value={processInfo.progress}
                />
              </div>
              <div className="progress-container__status">{processInfo.status}</div>
            </div>
          ) : (
            <Alert
              severity={info.type ? "success" : "error"}
              className="feedback-container__info"
            >
              {info.message}
            </Alert>
          )
        }
      </div>
      <div>
        <Typography variant="h6">{headline}</Typography>
      </div>
      <Box component={createForm ? "form" : "div"} autoComplete="on">
        {description}
        {children}
      </Box>
      {showRequiredTag && <p><span className="small-text">* erforderliche Information</span></p>}
    </div>
  );
};

export default FormContainer;
