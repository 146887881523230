interface Props {
  children: React.ReactNode;
  headline?: string;
  text?: string;
}

const FormSection = ({ children, headline, text }: Props) => {
  return (
    <div className="form-section">
      <div className="big-text">{ headline }</div>
      { text }
      <div>
        { children }
      </div>
    </div>
  );
};

export default FormSection;
