import { useState } from "react";
import { Link } from "react-router-dom";

import {FormOut} from "../../utils/formUtils";

import TextInput from "./inputs/TextInput";
import ButtonInput from "./inputs/ButtonInput";

import "../../style/AuthForm.css";

interface LoginFormData {
  email: string;
  password: string;
}

export type LoginFormOut = FormOut<LoginFormData>;

interface Props {
  actionLoading?: boolean;
  onSubmit: (data: LoginFormOut) => void;
}

const LoginForm = ({ actionLoading, onSubmit }: Props) => {

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const checkInput = async (): Promise<void> => {
      if(!email || !password) {
        throw new Error("Nicht alle erforderlichen Felder wurden ausgefüllt.");
      }
  };
  const getOutData = (): LoginFormOut => {
    return {
      data: {
        email,
        password
      },
      check: checkInput
    };
  };

  return (
    <>
      <TextInput
        label="Email"
        type="email"
        onChange={(value: string) => setEmail(value)}
        autoComplete="username"
      />
      <TextInput
        label="Passwort"
        type="password"
        onChange={(value: string) => setPassword(value)}
        autoComplete="current-password"
        helperText={
          <Link to="/sendrecoveryemail" className="href-link">Passwort vergessen?</Link>
        }
      />
      <ButtonInput
        actionLoading={actionLoading}
        disabled={!email || !password}
        text="Anmelden"
        endIcon={" "}
        onClick={() => onSubmit(getOutData())}
        isSubmit={true}
        variant="outlined"
        params={{className: "loginLoadingButton"}}
      />
    </>
  );
};

export default LoginForm;
