import { useState } from "react";

import {FormOut, checkEmailInput} from "../../utils/formUtils";

import "../../style/AuthForm.css";
import TextInput from "./inputs/TextInput";
import ButtonInput from "./inputs/ButtonInput";

interface ChangePasswordFormData {
  email: string;
}

export type ChangePasswordFormOut = FormOut<ChangePasswordFormData>;

interface Props {
  actionLoading?: boolean;
  onSubmit: (data: ChangePasswordFormOut) => void;
}


const SendRecoveryEmailForm = ({ actionLoading, onSubmit }: Props) => {

  const [email, setEmail] = useState<string>("");

  const checkInput = async (): Promise<void> => {
    if(!email) {
      throw new Error("Nicht alle erforderlichen Felder wurden ausgefüllt.");
    }
    else if(!checkEmailInput(email)) {
      throw new Error("Die eingegebene Email-Addresse ist ungültig.");
    }
  };

  const getOutData = (): ChangePasswordFormOut => {
    return {
      data: {
        email
      },
      check: checkInput
    };
  };

  return (
    <>
      <TextInput
        label="Email"
        type="email"
        onChange={(value: string) => setEmail(value)}
        autoComplete="username"
      />
      <ButtonInput
        actionLoading={actionLoading}
        disabled={!email}
        text="Senden"
        endIcon={" "}
        onClick={() => onSubmit(getOutData())}
        isSubmit={true}
        variant="outlined"
        params={{className: "loginLoadingButton"}}
      />
    </>
  );
};

export default SendRecoveryEmailForm;
