import MainContainer from "../components/MainContainer";

import { Typography } from "@mui/material";

interface Props {}

const Imprint = ({  }: Props) => {
  return (
    <MainContainer>
      <Typography variant="h5">Impressum</Typography>
      <p>Informationspflicht laut §5 E-Commerce Gesetz, §14 Unternehmensgesetzbuch, §63 Gewerbeordnung und Offenlegungspflicht laut §25 Mediengesetz.</p>  
      <p>
        Venbob <br/>
        Oskar Seierl <br/>
        Schönburgstraße 3 <br/>
        1040 Wien, <br/>
        Österreich
      </p>
      <p>
        <b>Unternehmensgegenstand:</b> Software
        {/*UID-Nummer: ATU12345678 ?
        GLN: 1234567891234 ?
        GISA: 12345678 ?*/}
      </p>
      <p>
        <b>Tel.:</b> +43 650 4040 537 <br/>
        <b>E-Mail:</b> office@venbob.com
      </p>
      <p>
        <b>Berufsrecht:</b> Gewerbeordnung: www.ris.bka.gv.at
      </p>
      <Typography variant="h6">EU-Streitschlichtung</Typography>
      <p>
        Gemäß Verordnung über Online-Streitbeilegung in Verbraucherangelegenheiten (ODR-Verordnung) möchten wir Sie über die Online-Streitbeilegungsplattform (OS-Plattform) informieren.
        Verbraucher haben die Möglichkeit, Beschwerden an die Online Streitbeilegungsplattform der Europäischen Kommission unter http://ec.europa.eu/odr?tid=121890394 zu richten. Die dafür notwendigen Kontaktdaten finden Sie oberhalb in unserem Impressum.
      </p>
      <p>
        Wir möchten Sie jedoch darauf hinweisen, dass wir nicht bereit oder verpflichtet sind, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
      </p>
      <Typography variant="h6">Haftung für Inhalte dieser Website</Typography>
      <p>
        Wir entwickeln die Inhalte dieser Website ständig weiter und bemühen uns korrekte und aktuelle Informationen bereitzustellen.  Leider können wir keine Haftung für die Korrektheit aller Inhalte auf dieser Website übernehmen, speziell für jene, die seitens Dritter bereitgestellt wurden. Als Diensteanbieter sind wir nicht verpflichtet, die von Ihnen übermittelten oder gespeicherten Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
      </p>
      <p>
        Unsere Verpflichtungen zur Entfernung von Informationen oder zur Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen aufgrund von gerichtlichen oder behördlichen Anordnungen bleiben auch im Falle unserer Nichtverantwortlichkeit davon unberührt.
      </p>
      <p>
        Sollten Ihnen problematische oder rechtswidrige Inhalte auffallen, bitte wir Sie uns umgehend zu kontaktieren, damit wir die rechtswidrigen Inhalte entfernen können. Sie finden die Kontaktdaten im Impressum.
      </p>
      <Typography variant="h6">Urheberrechtshinweis</Typography>
      <p>
        Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos) unterliegen dem Urheberrecht. Bitte fragen Sie uns bevor Sie die Inhalte dieser Website verbreiten, vervielfältigen oder verwerten wie zum Beispiel auf anderen Websites erneut veröffentlichen. Falls notwendig, werden wir die unerlaubte Nutzung von Teilen der Inhalte unserer Seite rechtlich verfolgen.
      </p>
      <p>
        Sollten Sie auf dieser Webseite Inhalte finden, die das Urheberrecht verletzen, bitten wir Sie uns zu kontaktieren.
      </p>
    </MainContainer>
  );
};

export default Imprint;
