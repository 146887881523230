import {Checkbox, Paper} from "@mui/material";
import {ChangeEvent} from "react";

interface Props {
  children: React.ReactNode;
  groupName?: string;
  show?: boolean;
  groupSelected: boolean;
  onToggle: () => void;
}

const VendingMachinesGroupContainer = ({ children, groupName, show=true, groupSelected, onToggle }: Props) => {
  return (
    <Paper className="vending-machines-group-container" elevation={1} style={{display: show ? "" : "none"}}>
      <div className="vending-machines-group-container__headline big-text">
        { groupName } <Checkbox checked={groupSelected} onChange={(event: ChangeEvent<HTMLInputElement>) => onToggle()} />
      </div>

      <div className="vending-machines-group-container__vending-machines-container">
        { children }
      </div>
    </Paper>
  );
};

export default VendingMachinesGroupContainer;
