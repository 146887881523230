import {useDocs} from "./useDatabase";

export interface CheckoutSystem {
  name: string;
}

export interface CheckoutSystemData {
  [key: string]: CheckoutSystem;
}

export function useCheckoutSystems(): CheckoutSystemData | undefined {
  return useDocs("checkoutSystems", true) as CheckoutSystemData;
}