import {doc, collection, DocumentData, DocumentSnapshot, getDoc, getDocs, onSnapshot} from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../config/firebase";
import {docsToObject} from "../utils/dbUtils";

export function useDoc(collection: string, id: string | undefined) {
    const [data, setData] = useState<DocumentData | null | undefined>(undefined);

    useEffect(() => {
        let isSubscribed: boolean = true;

        if(id) {
            getDoc(doc(db, collection, id))
            .then((docSnap: DocumentSnapshot<DocumentData>) => {
                if (isSubscribed) {
                    setData(docSnap.data() || null);
                }
            });
        }

        return () => {
            isSubscribed = false;
        }
    }, [id]);

    return data;
}

export function useDocs(collectionName: string, mapKeys?: boolean, maybeNull?: boolean) {
    const [data, setData] = useState<DocumentData | object | undefined>(undefined);

    useEffect(() => {
        let isSubscribed: boolean = true;

        getDocs(collection(db, collectionName))
        .then((docs: any) => {
            if (isSubscribed) {
                if(mapKeys) {
                    setData(docsToObject(docs));
                }
                else {
                    setData(docs);
                }
            }
        });

        return () => {
            isSubscribed = false;
        }
    }, [collectionName]);

    return data;
}

export function useOnSnapshot(collection: string, id: string | undefined) {
    const [data, setData] = useState<DocumentData | null | undefined>(undefined);

    useEffect(() => {
        let isSubscribed: boolean = true;
        let unsub: () => any;

        if(id) {
            unsub = onSnapshot(doc(db, collection, id), (docSnap: DocumentSnapshot<DocumentData>) => {
                if(isSubscribed) {
                    setData(docSnap.data() || null);
                }
            });
        }

        return () => {
            isSubscribed = false;
            if(unsub) {
                unsub();
            }
        }
    }, [id]);

    return data;
}

export function useOnSnapshotCollection(path: string | undefined, mapKeys?: boolean) {
    const [data, setData] = useState<DocumentData | object | undefined>(undefined);

    useEffect(() => {
        let isSubscribed: boolean = true;
        let unsub: () => any;

        if(path) {
            unsub = onSnapshot(collection(db, path), (docSnap: any) => {
                if(isSubscribed) {
                    if(mapKeys) {
                        setData(docsToObject(docSnap.docs));
                    }
                    else {
                        setData(docSnap.docs);
                    }
                }
            });
        }

        return () => {
            isSubscribed = false;
            if(unsub) {
                unsub();
            }
        }
    }, [path]);

    return data;
}
