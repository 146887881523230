import { useState, useEffect } from "react";

import {FormOut} from "../../utils/formUtils";

import FormRow from "./containers/FormRow";
import RadioGroupInput from "./inputs/RadioGroupInput";
import TextInput from "./inputs/TextInput";
import ButtonInput from "./inputs/ButtonInput";
import {updateUser} from "../../utils/userUtils";
import {setDeepValue} from "../../utils/dbUtils";

export interface PersonalInformation {
  salutation: string;
  firstName: string;
  lastName: string;
  address: {
    street: string;
    postalCode: string;
    city: string;
  };
  mobileNumber: string;
}

export type PersonalInformationFormOut = FormOut<PersonalInformation>;
interface Props {
  loading?: boolean;
  actionLoading?: boolean;
  defaultData?: any;
  email: string | null | undefined;
  onChange?: (data: PersonalInformationFormOut) => void;
  onSubmit?: (data: PersonalInformationFormOut) => void;
}

const PersonalInformationForm = ({ loading, actionLoading, defaultData, email, onChange, onSubmit }: Props) => {

  const [profileData, setProfileData] = useState<any>(undefined);

  const isLoading = loading || profileData === undefined;

  useEffect(() => {
    if(defaultData) {
      setProfileData(JSON.parse(JSON.stringify(defaultData)));
    }
    else if(defaultData === null) {
      setProfileData({});
    }
  }, [defaultData]);

  const setValue = (key: string, value: string, deepKey?: string) => {
    setProfileData(setDeepValue(profileData, key, value, deepKey));
  };

  const checkInput = async (): Promise<void> => {
      if(
        !profileData?.firstName
        || !profileData?.lastName
        || !profileData?.address.street
        || !profileData?.address.postalCode
        || !profileData?.address.city
        || !profileData?.mobileNumber
      ) {
        throw new Error("Nicht alle erforderlichen Felder wurden ausgefüllt.");
      }
  };

  const saveInput = async (): Promise<void> => {
    await updateUser(profileData);
  };


  const getOutData = (): PersonalInformationFormOut => {
    return {
      data: {
        salutation: profileData?.salutation,
        firstName: profileData?.firstName,
        lastName: profileData?.lastName,
        address: {
          street: profileData?.address?.street,
          postalCode: profileData?.address?.postalCode,
          city: profileData?.address?.city
        },
        mobileNumber: profileData?.mobileNumber
      },
      check: checkInput,
      save: saveInput
    };
  };

  if(onChange) {
    useEffect(() => {
      onChange(getOutData());
    }, [profileData]);
  }

  return (
    <>
      <FormRow label="Anrede" isRequired={true}>
        <RadioGroupInput
          loading={isLoading}
          pickSelection={["Herr", "Frau"]}
          value={profileData?.salutation}
          onChange={(value: string) => setValue("salutation", value)}
        />
      </FormRow>

      <FormRow label="Vollständiger Name" isRequired={true} >
        <TextInput
          loading={isLoading}
          label="Vorname"
          defaultValue={profileData?.firstName || ""}
          onChange={(value: string) => setValue("firstName", value)}
        />
        <TextInput
          loading={isLoading}
          label="Nachname"
          defaultValue={profileData?.lastName || ""}
          onChange={(value: string) => setValue("lastName", value)}
        />
      </FormRow>

      <FormRow label="Geschäftsaddresse" isRequired={true} >
        <TextInput
          loading={isLoading}
          label="Straße + Hausnummer"
          defaultValue={profileData?.address?.street || ""}
          onChange={(value: string) => setValue("address", value, "street")}
        />
        <TextInput
          loading={isLoading}
          label="Postleitzahl"
          defaultValue={profileData?.address?.postalCode || ""}
          onChange={(value: string) => setValue("address", value, "postalCode")}
        />
        <TextInput
          loading={isLoading}
          label="Ort"
          defaultValue={profileData?.address?.city || ""}
          onChange={(value: string) => setValue("address", value, "city")}
        />
      </FormRow>

      <FormRow label="Email" isRequired={true} >
        <TextInput
          loading={isLoading}
          disabled={true}
          label="Email"
          defaultValue={email || "...loading..."}
          type="email"
        />
      </FormRow>

      <FormRow label="Telefon" isRequired={true} >
        <TextInput
          loading={isLoading}
          label="Telefonnummer"
          defaultValue={profileData?.mobileNumber || ""}
          onChange={(value: string) => setValue("mobileNumber", value)}
          type="tel"
        />
      </FormRow>

      {
        onSubmit && (
          <ButtonInput
            actionLoading={actionLoading}
            disabled={isLoading}
            text="Speichern"
            onClick={() => onSubmit(getOutData())}
          />
        )
      } 
    </>
  );
};

export default PersonalInformationForm;
