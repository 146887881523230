import {ProviderData} from "../../hooks/useProviders";
import {LoginData} from "../../hooks/useCredentials";
import {CheckoutSystemData} from "../../hooks/useCheckoutSystems";

import {Box, Card, Skeleton} from "@mui/material";
import { Person, Store, ContactMail, Cloud, Settings, Check, Close } from '@mui/icons-material';

import "../../style/ProfileInfoCards.css";

interface Props {
  loading: boolean;
  email?: string | null;
  userData?: any;
  credentials?: LoginData;
  checkoutSystems?: CheckoutSystemData;
  providers?: ProviderData;
}

const ProfileInfoCards = ({ loading, email, userData={}, credentials={}, checkoutSystems={}, providers={} }: Props) => {

  if(loading) {
    return (
      <Box className="info-card-container">
        <Skeleton variant="rounded" width={210} height={240} />
        <Skeleton variant="rounded" width={210} height={240} />
        <Skeleton variant="rounded" width={210} height={240} />
        <Skeleton variant="rounded" width={210} height={240} />
        <Skeleton variant="rounded" width={210} height={240} />
      </Box>
    );
  }

  return (
    <Box className="info-card-container">
      <Card className="info-card-container__card">
        <p>
          <Person />
        </p>
        <p>
          <span>Anrede:</span> <span>{ userData.salutation }</span>
        </p>
        <p>
          <span>Vorname:</span> <span>{ userData.firstName }</span>
        </p>
        <p>
          <span>Nachname:</span> <span>{ userData.lastName }</span>
        </p>
      </Card>
      <Card className="info-card-container__card">
        <p>
          <Store />
        </p>
        <p>
          <span>Straße:</span> <span>{ userData.address.street }</span>
        </p>
        <p>
          <span>PLZ:</span> <span>{ userData.address.postalCode }</span>
        </p>
        <p>
          <span>Ort:</span> <span>{ userData.address.city }</span>
        </p>
      </Card>
      <Card className="info-card-container__card">
        <p>
          <ContactMail />
        </p>
        <p>
          <span>Email:</span> <span>{ email }</span>
        </p>
        <p>
          <span>Tel.:</span> <span>{ userData.mobileNumber }</span>
        </p>
      </Card>
      <Card className="info-card-container__card">
        <p>
          <Cloud />
        </p>
        {
          Object.keys(providers).map((key: string) =>
              <p key={key}>
                <span>{providers[key].name}:</span>
                <span>
                  { credentials[key].active ? <Check sx={{color: "success.main"}} /> : <Close sx={{color: "error.main"}} /> }
                </span>
              </p>
          )
        }
      </Card>
      <Card className="info-card-container__card">
        <p>
          <Settings />
        </p>
        <p>
          <span>QR-Größe:</span> <span>{ userData?.settings?.qrCode?.size }px</span>
        </p>
        <p>
          <span>QR-Komplexität:</span> <span>{ userData?.settings?.qrCode?.complexity } Zeichen</span>
        </p>
        {
          userData.settings?.checkoutSystem && (
            <p>
            <span>Kassensystem:</span> <span>{ checkoutSystems[userData.settings?.checkoutSystem].name }</span>
            </p>
          )
        }
        <p>
          <span>Gruppenverwaltung:</span>
          <span>
            { userData.settings?.groupManagement ? <Check sx={{color: "success.main"}} /> : <Close sx={{color: "error.main"}} /> }
          </span>
        </p>
      </Card>
    </Box>
  );
};

export default ProfileInfoCards;
