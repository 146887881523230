import { useState } from "react";
import { Link } from "react-router-dom";

import { User } from "firebase/auth";
import { DocumentData } from "firebase/firestore";

import { useFirebaseUser, useUser } from "../hooks/userHook";

import { AppBar, Box, Container, Toolbar, Typography, IconButton, Menu, MenuItem, Button, Avatar, Skeleton } from "@mui/material";
import { Menu as MenuIcon, Login } from '@mui/icons-material';

import "../style/Navbar.css"; 

interface Props {}

const pages = ["Befüllvorschläge erstellen"];

const Navbar = ({}: Props) => {

  const firebaseUser: User | null | undefined = useFirebaseUser();
  const user: DocumentData | null | undefined = useUser(firebaseUser);

  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

  const firstName: string = user?.firstName || firebaseUser?.email || "";
  const lastName: string = user?.lastName || "";

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const avatarText = (...args: Array<string>) => {
    return (args.map((arg) => arg[0]).join('')).toUpperCase();
  };
  
  return (
    <AppBar position="static" className="navbar">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box component="img" src="images/logo.png" sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}></Box>
          
          <Link to="/" className="hidden-href-link">
            <Typography
              variant="h5"
              noWrap
              className="logo-font"
              sx={{
                display: { xs: 'none', md: 'flex' }
              }}
            >
              Venbob
            </Typography>
          </Link>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Link to="/" className="hidden-href-link navbar__link">
                    <Typography textAlign="center">{page}</Typography>
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box component="img" src="images/logo.png" sx={{ display: { xs: 'flex', md: 'none' } }}></Box>
          <Link to="/" className="hidden-href-link navbar__link">
            <Typography
              variant="h5"
              noWrap
              className="logo-font logo-font--small"
              sx={{
                display: { xs: 'flex', md: 'none' },
                fontSize: '1em' ,
                flexGrow: 1
              }}
            >
              Venbob
            </Typography>
          </Link>

          <Box className="navbar-button-container" sx={{ display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                className="navbar-button-container__button"
              >
                {page}
              </Button>
            ))}
          </Box>
          <Link to={(firebaseUser === null) ? "/login" : "/profile"} className="hidden-href-link">
            <Box sx={{ flexGrow: 0 }} className="navbar__profile-container">
              {
                firstName ? (
                  <Button className="navbar-button-container__button" sx={{display: { xs: 'none', md: 'inherit' }}}>Hallo { firstName }!</Button>
                ) : (
                  (firebaseUser === null) ? (
                    <Button className="navbar-button-container__button" sx={{display: { xs: 'none', md: 'inherit' }}}>Zum Login</Button>
                  ) : (
                    <Skeleton variant="text" sx={{ fontSize: '1.2rem', width: "100px" }} />
                  )
                )
              }
              
              <IconButton sx={{ p: 0 }}>
                <Avatar>
                  {
                    (firebaseUser === null) ? (
                      <Login />
                    ) : (
                      firstName !== "" && avatarText(firstName, lastName)
                    )
                  }
                </Avatar>
              </IconButton>
            </Box>
          </Link>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navbar;
