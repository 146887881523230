import { useState, useEffect, SyntheticEvent } from "react";

import { getAvailableVendingMachines } from "../../config/cloudFunctions"

import {ProviderData} from "../../hooks/useProviders";
import {FormOut} from "../../utils/formUtils";
import {VendingMachinesData} from "../../hooks/useVendingMachines";
import {LoginData} from "../../hooks/useCredentials";
import {setUserMultiple, updateUserMultiple} from "../../utils/userUtils";

import ButtonInput from "./inputs/ButtonInput";
import FormRow from "./containers/FormRow";
import MultipleButtonContainer from "./containers/MultipleButtonContainer";
import TextInput from "./inputs/TextInput";

import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import {doc, deleteDoc} from "firebase/firestore";
import {auth, db} from "../../config/firebase";
import ButtonWrapper from "./containers/ButtonWrapper";

export type ProviderFormOut = FormOut<LoginData>;

export interface ProviderCheckInputOut {
  vendingMachines: VendingMachinesData;
  isRefresh?: boolean;
}

interface Props {
  actionLoading?: boolean;
  defaultData?: any;
  providers?: ProviderData;
  vendingMachinesAll?: VendingMachinesData;
  onChange?: (data: ProviderFormOut) => void;
  onSubmit?: (data: ProviderFormOut) => void;
}

const ProviderForm = ({actionLoading, defaultData, providers={}, vendingMachinesAll={}, onChange, onSubmit}: Props) => {

  const [refreshActionLoading, setRefreshActionLoading] = useState<boolean>(false);

  if(!defaultData) {
    if(providers) {
      let loginData: LoginData = {};
      Object.keys(providers).forEach((key: string) => {
        loginData[key] = {
          active: false,
          username: "",
          password: ""
        }
      });
      defaultData = loginData;
    }
  }

  const [formLoginData, setFormLoginData] = useState<LoginData>(JSON.parse(JSON.stringify(defaultData)));

  const updateFormObject = (providerId: string, key: string, value: string | boolean) => {
    const currentObject: any = { ...formLoginData };
    currentObject[providerId][key] = value;
    setFormLoginData(currentObject);
  };

  const checkInput = async (isRefresh?: boolean): Promise<ProviderCheckInputOut | undefined> => {
    let foundOneActive = false;
    for (const key in formLoginData) {
      if(formLoginData[key].active) {
        foundOneActive = true;
      }
      if(formLoginData[key].active && (!formLoginData[key].username || !formLoginData[key].password)) {
        throw new Error("Nicht alle erforderlichen Felder wurden ausgefüllt.");
      }
    }
    if(!foundOneActive) {
      throw new Error("Kein Anbieter wurde ausgewählt.");
    }

    if(!isRefresh) {
      let foundDifference = false;
      for (const key in formLoginData) {
        if (
          formLoginData[key].active !== defaultData[key].active ||
          formLoginData[key].username !== defaultData[key].username ||
          formLoginData[key].password !== defaultData[key].password
        ) {
          foundDifference = true;
          break;
        }
      }
      if (!foundDifference) {
        throw new Error("Eingaben wurden nicht verändert.");
      }
    }

    try {
      return {
        vendingMachines: (await getAvailableVendingMachines({credentials: formLoginData})).data as VendingMachinesData,
        isRefresh: isRefresh
      };
    }
    catch (error: any) {
      throw error;
    }
  };

  const saveInput = async (detectedVendingMachines: VendingMachinesData, isRefresh?: boolean): Promise<void> => {
    await updateUserMultiple(formLoginData, "credentials");

    let newVendingMachines: VendingMachinesData = {};
    if(isRefresh) {
      let key: string;
      for(key in vendingMachinesAll) {
        await deleteDoc(doc(db, "users/" + auth.currentUser?.uid + "/vendingMachines/" + key));
      }
    }
    else {
      let key: string;
      for (key in detectedVendingMachines) {
        if (!vendingMachinesAll[key]) {
          newVendingMachines = {...newVendingMachines, [key]: detectedVendingMachines[key]}
        }
      }
    }

    await setUserMultiple(isRefresh ? detectedVendingMachines : newVendingMachines, "vendingMachines");

    isRefresh && setRefreshActionLoading(false);
  };

  const getOutData = (isRefresh?: boolean): ProviderFormOut => {
    return {
      data: formLoginData,
      check: () => checkInput(isRefresh),
      save: saveInput
    };
  };

  if(onChange) {
    useEffect(() => {
      onChange(getOutData());
    }, [formLoginData]);
  }

  return (
    <>
      <FormRow label="Erwünschte Anbieter" isRequired={true}>
        <FormGroup>
          {
            Object.keys(providers).map((key: string) =>
              <FormControlLabel
                key={key}
                control={<Checkbox checked={formLoginData[key].active} />}
                label={providers[key].name + " (" + providers[key].baseUrl + ")" + (!providers[key].active ? " (coming soon)" : "")}
                disabled={!providers[key].active}
                onChange={(e: SyntheticEvent) => updateFormObject(key, "active", !formLoginData[key].active)}
              />
            )
          }
        </FormGroup>
      </FormRow>
      {
        Object.keys(providers).map((key: string) =>
          <div key={key} style={{ opacity: formLoginData[key].active ? '1' : '0.5' }}>
            <FormRow label="Anmeldedaten" isRequired={true} createForm={true}>
              <TextInput
                label="Benutzername"
                disabled={!formLoginData[key].active}
                defaultValue={formLoginData[key].username}
                onChange={(value: string) => updateFormObject(key, "username", value)}
                autoComplete="current-password"
              />
              <TextInput
                label="Passwort"
                disabled={!formLoginData[key].active}
                defaultValue={formLoginData[key].password}
                type="password"
                onChange={(value: string) => updateFormObject(key, "password", value)}
                autoComplete="current-password"
              />
            </FormRow>
          </div>
        )
      }
      {
        onSubmit && (
          <MultipleButtonContainer>
            <ButtonInput
              actionLoading={actionLoading && !refreshActionLoading}
              disabled={refreshActionLoading}
              text="Speichern"
              onClick={() => onSubmit(getOutData())}
            />
            <ButtonWrapper
              label={<>Automatendaten werden neu abgerufen und abgespeichert.<br/>Achtung: Hierbei gehen sämtliche benutzerdefinierten Namen verloren.</>}
              disabled={actionLoading && !refreshActionLoading}
            >
              <ButtonInput
                actionLoading={refreshActionLoading}
                disabled={actionLoading && !refreshActionLoading}
                text="Automatendaten aktualisieren"
                onClick={() => {
                    setRefreshActionLoading(true);
                    onSubmit(getOutData(true))
                  }
                }
              />
            </ButtonWrapper>
          </MultipleButtonContainer>
        )
      } 
    </>
  );
};

export default ProviderForm;
