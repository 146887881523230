import {Skeleton} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {Done} from "@mui/icons-material";

interface Props {
  loading?: boolean;
  actionLoading?: boolean;
  disabled?: boolean;
  text: string | React.ReactNode;
  endIcon?: React.ReactNode;
  loadingPosition?: string | undefined;
  onClick: () => void;
  isSubmit?: boolean;
  variant?: "outlined" | "text" | "contained" | undefined;
  params?: any;
}

const ButtonInput = (
  {
    loading,
    actionLoading,
    disabled=false,
    text,
    endIcon=<Done />,
    loadingPosition="end",
    onClick,
    isSubmit=false,
    variant="text",
    params={}
  }: Props) => {

  if(loading) {
    return <div><Skeleton variant="rounded" width={105} height={36} /></div>;
  }

  let realLoadingPosition: string | undefined = loadingPosition;
  if(endIcon === null) {
    realLoadingPosition = undefined;
  }

  const expandedOnClick = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    if(isSubmit) {
      ev.preventDefault();
    }
    onClick();
  };

  return (
    <LoadingButton
      loading={actionLoading}
      disabled={disabled}
      onClick={expandedOnClick}
      loadingPosition={realLoadingPosition}
      endIcon={endIcon}
      variant={variant}
      type={isSubmit ? "submit" : "button"}
      {...params}
    >
      { text }
    </LoadingButton>
  );
};

export default ButtonInput;
