import {ChangeEvent, useEffect} from "react";
import {FormControlLabel, Radio, RadioGroup, Skeleton} from "@mui/material";

interface Props {
  loading?: boolean;
  pickSelection: Array<string>;
  value: string;
  onChange: (value: string) => void;
}

const RadioGroupInput = ({ loading, pickSelection, value, onChange }: Props) => {

  if(loading) {
   return (
     <>
       {
         pickSelection.map((selection: string) => <div key={selection}><Skeleton variant="rectangular" width={160} height={42}/></div>)
       }
     </>
   );
  }

  useEffect(() => {
    onChange(value || pickSelection[0]);
  }, []);

  return (
    <RadioGroup
      value={value || pickSelection[0]}
      onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
      row
    >
      {
        pickSelection.map((selection: string) => {
          return (
            <FormControlLabel
              key={selection}
              value={selection}
              control={<Radio />}
              label={selection}
            />
          );
        })
      }
    </RadioGroup>
  );
};

export default RadioGroupInput;
