import {useOnSnapshotCollection} from "./useDatabase";

export interface LoginData {
  [key: string]: {
    active: boolean;
    username: string;
    password: string;
  };
}

export function useCredentials(userId: string | undefined): LoginData | undefined {
  return useOnSnapshotCollection(userId ? "users/" + userId + "/credentials" : undefined, true) as LoginData;
}