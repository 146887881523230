import { Typography } from "@mui/material";

import MainContainer from "../components/MainContainer";

interface Props {}

const NotFound = ({}: Props) => {

  return (
    <MainContainer>
      <Typography variant="h5">404</Typography>
      <Typography variant="h5">Seite wurde nicht gefunden.</Typography>
    </MainContainer>
  );
};

export default NotFound;
