interface Props {
  children: React.ReactNode;
  label?: string | React.ReactNode;
  disabled?: boolean;
}

const ButtonWrapper = ({ children, label, disabled=false }: Props) => {

  return (
    <div className={disabled ? "unavailable" : ""}>
      { children }
      <br/>
      <div className="button-wrapper__text"> { label }</div>
    </div>
  );
};

export default ButtonWrapper;
