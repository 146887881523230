import {Box} from "@mui/material";

import ButtonInput from "./ButtonInput";

import "../../../style/ProfileHeader.css";

interface Props {
  loading?: boolean;
  actionLoading: boolean;
  steps: Array<string>;
  activeStep: number;
  handleBack: () => void;
  handleNext: () => void;
}

const StepperNavigationButtons = ({ loading, actionLoading, steps, activeStep, handleBack, handleNext }: Props) => {
  return (
      <Box className="button-container">
        <ButtonInput
            disabled={activeStep === 0 || loading}
            text="Zurück"
            onClick={handleBack}
        />
        <Box className="button-container__placeholder" />
        <ButtonInput
          actionLoading={actionLoading}
          disabled={loading}
          text={activeStep === steps.length - 1 ? "Speichern" : "Weiter"}
          endIcon={activeStep === steps.length - 1 ? undefined : " "}
          onClick={handleNext}
        />
      </Box>
  );
};

export default StepperNavigationButtons;
