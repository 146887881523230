import {ChangeEvent, useState} from "react";

import {FormOut} from "../../utils/formUtils";
import {updateUser} from "../../utils/userUtils";
import {setDeepValue} from "../../utils/dbUtils";
import {CheckoutSystemData} from "../../hooks/useCheckoutSystems";

import FormRow from "./containers/FormRow";
import ButtonInput from "./inputs/ButtonInput";

import {Select, MenuItem, FormControl, SelectChangeEvent, Slider, Checkbox} from "@mui/material";
import FormSection from "./containers/FormSection";

export interface QrCodeSettings {
  complexity: number;
  size: number;
}

export interface SettingsData {
  qrCode: QrCodeSettings;
  checkoutSystem: string;
  groupManagement: boolean;
}

interface WrappedSettingsData {
  settings: SettingsData
}

export type QrCodeSettingsFormOut = FormOut<WrappedSettingsData>;

interface Props {
  actionLoading?: boolean;
  defaultData?: SettingsData;
  checkoutSystems?: CheckoutSystemData;
  onSubmit: (data: QrCodeSettingsFormOut) => void;
}

const inputPattern: any = {
  sizeSlider: {
    min: 50,
    max: 500
  },
  complexitySlider: {
    min: 200,
    max: 2000
  }
};

const SettingsForm = ({actionLoading, defaultData, checkoutSystems={}, onSubmit }: Props) => {

  const [settingsData, setSettingsData] = useState<any>(defaultData);

  const setValue = (key: string, value: any, deepKey?: string) => {
    setSettingsData(setDeepValue(settingsData, key, value, deepKey));
  };

  const checkInput = async (): Promise<void> => {
    if(!settingsData?.qrCode?.size || !settingsData?.qrCode?.complexity) {
      throw new Error("Nicht alle erforderlichen Felder wurden ausgefüllt.")
    }
    else if(
      settingsData?.qrCode?.size < inputPattern.sizeSlider.min ||
      settingsData?.qrCode?.size > inputPattern.sizeSlider.max ||
      settingsData?.qrCode?.complexity < inputPattern.complexitySlider.min ||
      settingsData?.qrCode?.complexity > inputPattern.complexitySlider.max
      ) {
      throw new Error("Eingabekriterien wurden nicht erfüllt.");
    }
  };

  const out = {settings: settingsData};

  const saveInput = async (): Promise<void> => {
    await updateUser(out);
  };

  const getOutData = (): QrCodeSettingsFormOut => {
    return {
      data: out,
      check: checkInput,
      save: saveInput
    };
  };

  return (
    <>
      <FormSection
        headline="QR-Code"
        text="Druch das einstellen von QR-Code Größe und Komplexität kann der QR-Code von beinahe jedem Scan-Gerät erfolgreich und schnell gelesen werden."
      >
        <FormRow label={"Größe: " + settingsData?.qrCode?.size + "px"} isRequired={true}>
          <div className="field-row__special-wrapper">
            <Slider
              value={settingsData?.qrCode?.size}
              valueLabelDisplay="auto"
              step={10}
              min={inputPattern.sizeSlider.min}
              max={inputPattern.sizeSlider.max}
              onChange={(e: Event, value: number | Array<number>) => setValue("qrCode", value, "size")}
            />
          </div>
        </FormRow>
        <FormRow label={"Komplexität: " + settingsData?.qrCode?.complexity + "Zeichen"} isRequired={true}>
          <div className="field-row__special-wrapper">
            <Slider
              value={settingsData?.qrCode?.complexity}
              valueLabelDisplay="auto"
              step={100}
              min={inputPattern.complexitySlider.min}
              max={inputPattern.complexitySlider.max}
              onChange={(e: Event, value: number | Array<number>) => setValue("qrCode", value, "complexity")}
            />
          </div>
        </FormRow>
      </FormSection>
      <FormSection headline="Kassensystem">
        <FormRow label="System">
          <FormControl className="field-row__special-wrapper">
            <Select
              value={settingsData?.checkoutSystem}
              displayEmpty
              onChange={(event: SelectChangeEvent) => setValue("checkoutSystem", event.target.value)}
            >
              <MenuItem value="">-</MenuItem>
              {
                Object.keys(checkoutSystems).map((key: string, index: number) => <MenuItem key={index} value={key}>{checkoutSystems[key].name}</MenuItem>)
              }
            </Select>
          </FormControl>
        </FormRow>
      </FormSection>
      <FormSection headline="Gruppenverwaltung">
        <FormRow label="Aktiviert">
          <Checkbox checked={settingsData?.groupManagement} onChange={(event: ChangeEvent<HTMLInputElement>) => setValue("groupManagement", event.target.checked)} />
        </FormRow>
      </FormSection>

      <ButtonInput
        actionLoading={actionLoading}
        text="Speichern"
        onClick={() => onSubmit(getOutData())}
      />
    </>
  );
};

export default SettingsForm;
