import { useState, useEffect } from "react";

import {FormOut, checkPasswordInput} from "../../utils/formUtils";

import {Alert} from "@mui/material";
import FormRow from "./containers/FormRow";
import TextInput from "./inputs/TextInput";
import ButtonInput from "./inputs/ButtonInput";

export interface ResetPasswordFormData {
  password1: string;
  password2: string;
}

export type ResetPasswordFormOut = FormOut<ResetPasswordFormData>;

interface Props {
  loading?: boolean;
  actionLoading?: boolean;
  onChange?: (data: ResetPasswordFormOut) => void;
  onSubmit?: (data: ResetPasswordFormOut) => void;
}

const ResetPasswordForm = ({ loading, actionLoading, onChange, onSubmit }: Props) => {

  const [password1, setPassword1] = useState<string>("");
  const [password2, setPassword2] = useState<string>("");

  const getOutData = (): ResetPasswordFormOut => {
    return {
      data: {
        password1: password1,
        password2: password2
      },
      check: async () => checkPasswordInput(password1, password2)
    };
  };

  if(onChange) {
    useEffect(() => {
      onChange(getOutData());
    }, [password1, password2]);
  }

  return (
    <>
      <Alert severity="info" className="stepper-info">Ihr Passwort muss mindestens 6 Zeichen lang sein.</Alert>

      <TextInput
        hidden={true}
        label="email"
        type="text"
        autoComplete="email"
      />

      <FormRow label="Neues Passwort" isRequired={true}>
        <TextInput
          loading={loading}
          label="Passwort"
          type="password"
          onChange={(value: string) => setPassword1(value)}
          autoComplete="new-password"
        />
      </FormRow>

      <FormRow label="Neues Passwort wiederholen" isRequired={true}>
        <TextInput
          loading={loading}
          label="Passwort Wiederholung"
          type="password"
          onChange={(value: string) => setPassword2(value)}
          autoComplete="new-password"
        />
      </FormRow>
      {
        onSubmit && (
          <ButtonInput
            disabled={loading}
            actionLoading={actionLoading}
            text="Speichern"
            onClick={() => onSubmit(getOutData())}
          />
        )
      } 
    </>
  );
};

export default ResetPasswordForm;
