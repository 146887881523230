import { Typography } from "@mui/material";
import MainContainer from "../components/MainContainer";

interface Props {}

const TermsOfService = ({  }: Props) => {
  return (
    <MainContainer>
      <Typography variant="h5">Nutzungsbedingungen</Typography>
        {/*<p>
        Das Produkt kann bis auf Widerruf kostenfrei benutzt werden. <br/>
        Ein Widerruf kann jederzeit aus einem beliebigen Grund erfolgen.
      </p>  */}
      <p>
        Es wird keine Haftung im Zuge der Anwendung übernommen.
      </p>
        {/*<p>
        Der Betreiber ist nicht verpflichtet Support zu leisten. <br/>
        Eine persönliche Untersützung kann nur gegen gesonderdes Entgeld erfolgen.
      </p>*/}
      <p>
        Die Anwendung ist Urheberrechtlich geschützt und darf nicht kopiert und nicht weiter gegeben werden.
      </p>
    </MainContainer>
  );
};

export default TermsOfService;
