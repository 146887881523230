import { useState, useEffect } from "react";

import {FormOut} from "../../utils/formUtils";
import {VendingMachinesData} from "../../hooks/useVendingMachines";
import {ProviderData} from "../../hooks/useProviders";
import {GroupsData} from "../../hooks/useGroups";
import {setDeepValue} from "../../utils/dbUtils";

import ButtonInput from "./inputs/ButtonInput";
import TextInput from "./inputs/TextInput";
import {updateUserMultiple} from "../../utils/userUtils";

import {FormControl, MenuItem, Select, SelectChangeEvent, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow} from "@mui/material";

export type VendingMachinesFormOut = FormOut<VendingMachinesData>;

interface Props {
  actionLoading?: boolean;
  vendingMachines: any;
  providers?: ProviderData;
  groups?: GroupsData;
  groupManagement?: boolean;
  onSubmit: (data: VendingMachinesFormOut) => void;
}

const VendingMachinesForm = ({ actionLoading, vendingMachines, providers={}, groups={}, groupManagement, onSubmit }: Props) => {

  const [vendingMachinesData, setVendingMachinesData] = useState<VendingMachinesData>({});



  const checkInput = async (): Promise<void> => {

  };

  const saveInput = async (): Promise<void> => {
    await updateUserMultiple(vendingMachinesData, "vendingMachines");
  };

  const getOutData = (): VendingMachinesFormOut => {
    return {
      data: vendingMachinesData,
      check: checkInput,
      save: saveInput
    };
  };

  const setValue = (key: string, value: string, deepKey?: string) => {
    setVendingMachinesData(setDeepValue(vendingMachinesData, key, value, deepKey));
  };

  useEffect(() => {
    if(!actionLoading) {
      setVendingMachinesData(vendingMachines);
    }
  }, [vendingMachines]);

  return (
    <>
      <TableContainer>
        <Table className="form-table form-table--vending-machines">
          <TableHead>
            <TableRow>
              <TableCell>Anbieter-Name</TableCell>
              <TableCell>Anbieter-URL</TableCell>
              <TableCell>UID</TableCell>
              <TableCell>Name</TableCell>
              { groupManagement && <TableCell>Gruppe</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {
              Object.keys(vendingMachinesData).map((vendingMachinesKey: string, index: number) => {
                return (
                  <TableRow key={vendingMachinesKey}>
                    <TableCell>{ providers[vendingMachinesData[vendingMachinesKey].providerId].name }</TableCell>
                    <TableCell>{ providers[vendingMachinesData[vendingMachinesKey].providerId].baseUrl }</TableCell>
                    <TableCell>{ vendingMachinesKey }</TableCell>
                    <TableCell>
                      <TextInput
                        label="Name"
                        defaultValue={vendingMachinesData[vendingMachinesKey].name}
                        onChange={(value: string) => setValue(vendingMachinesKey, value, "name")}
                      />
                    </TableCell>
                    {
                      groupManagement && (
                        <TableCell>
                          <FormControl className="field-row__special-wrapper">
                            <Select
                              value={groups[vendingMachinesData[vendingMachinesKey].groupId] ? vendingMachinesData[vendingMachinesKey].groupId : ""}
                              displayEmpty
                              onChange={(event: SelectChangeEvent) => setValue(vendingMachinesKey, event.target.value, "groupId")}
                            >
                              <MenuItem value=''>-</MenuItem>
                              {
                                Object.keys(groups).map((groupKey: string, index: number) => <MenuItem key={groupKey} value={groupKey}>{groups[groupKey].name}</MenuItem>)
                              }
                            </Select>
                          </FormControl>
                        </TableCell>
                      )
                    }
                  </TableRow>
                );
              })
            }
          </TableBody>
        </Table>
      </TableContainer>

      <ButtonInput
        actionLoading={actionLoading}
        text="Speichern"
        onClick={() => onSubmit(getOutData())}
      />
    </>
  );
};

export default VendingMachinesForm;
