import {DocumentData} from "firebase/firestore";

export function docsToObject(docs: any) {
  let object: any = {};
  docs.forEach((doc: DocumentData) => {
    object[doc.id] = doc.data();
  });
  return object;
}

export function setDeepValue(data: object, key: string, value: string, deepKey?: string) {
  const copy = JSON.parse(JSON.stringify(data));
  if(deepKey) {
    if(copy[key] === undefined) {
      copy[key] = {};
    }
    copy[key][deepKey] = value;
  }
  else {
    copy[key] = value;
  }
  return copy;
}