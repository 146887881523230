import { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import routes from "./config/routes";
import AuthChecker from "./components/utils/AuthChecker";

function App() {

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Routes>
        {routes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={
              route.protected ? (
                <AuthChecker
                  render={
                    (loading, firebaseUser, user) => <route.component pageLoading={loading} firebaseUser={firebaseUser} user={user} />
                  }
                />
              ) : (
                <route.component />
              )
            }
          />
        ))}
      </Routes>
    </BrowserRouter>
  );
}

export default App;