import {LoginData} from "./useCredentials";
import {useOnSnapshotCollection} from "./useDatabase";

export interface VendingMachinesData {
  [key: string]: {
    name: string;
    providerId: string;
    groupId: string;
  };
}

export function useVendingMachinesAll(userId: string | undefined): VendingMachinesData | undefined {
  return useOnSnapshotCollection(userId ? "users/" + userId + "/vendingMachines" : undefined, true) as VendingMachinesData;
}

export function useVendingMachines(userId: string | undefined, credentials?: LoginData, vendingMachinesAll?: VendingMachinesData | false): VendingMachinesData | undefined {
  if(vendingMachinesAll === false) {
    vendingMachinesAll = useVendingMachinesAll(userId);
  }

  if(userId && credentials && vendingMachinesAll) {
    let activeVendingMachines: VendingMachinesData = {};
    let key: string;
    for(key in vendingMachinesAll) {
      if(credentials[vendingMachinesAll[key].providerId].active) {
        activeVendingMachines = {...activeVendingMachines, [key]: vendingMachinesAll[key]}
      }
    }
    return activeVendingMachines
  }

  return undefined;
}