interface Props {
  children: React.ReactNode;
  row?: boolean
}

const MultipleButtonContainer = ({ children, row }: Props) => {

  return (
    <div className={"multiple-button-container " + (row ? "multiple-button-container--row" : "")}>
      { children }
    </div>
  );
};

export default MultipleButtonContainer;
