import React from 'react';
import ReactDOM from 'react-dom';
import './style/index.css';
import App from './App';
import {createTheme, ThemeProvider} from "@mui/material";
import {
  blue,
  blueGrey, brown,
  cyan,
  deepPurple,
  green,
  grey,
  indigo,
  lightGreen,
  lime,
  orange,
  red,
  yellow
} from "@mui/material/colors";

const theme = createTheme({
  palette: {

  },
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
