import Home from "../screens/Home";
import Profile from "../screens/Profile";
import Login from "../screens/Login";
import NotFound from "../screens/NotFound";
import Welcome from "../screens/Welcome";
import Imprint from "../screens/Imprint";
import Privacy from "../screens/Privacy";
import Register from "../screens/Register";
import TermsOfService from "../screens/TermsOfService";
import KickOff from "../screens/KickOff";
import SendRecoveryEmail from "../screens/SendRecoveryEmail";

interface RouteType {
    path: string;
    component: any;
    name: string;
    protected: boolean;
  }
  
  const routes: RouteType[] = [
    {
      path: "/",
      component: Home,
      name: "Home Screen",
      protected: true
    },
    {
      path: "/profile",
      component: Profile,
      name: "Profile Screen",
      protected: true
    },
    {
      path: "/welcome",
      component: Welcome,
      name: "Welcome Screen",
      protected: true
    },
    {
      path: "/login",
      component: Login,
      name: "Login Screen",
      protected: false
    },
    {
      path: "/register",
      component: Register,
      name: "Register Screen",
      protected: false
    },
    {
      path: "/sendrecoveryemail",
      component: SendRecoveryEmail,
      name: "Send Recovery Email Screen",
      protected: false
    },
    {
      path: "/kickoff",
      component: KickOff,
      name: "Kickoff Screen",
      protected: false
    },
    {
      path: "/imprint",
      component: Imprint,
      name: "Imprint Screen",
      protected: false
    },
    {
      path: "/privacy",
      component: Privacy,
      name: "Privacy Screen",
      protected: false
    },
    {
      path: "/termsofservice",
      component: TermsOfService,
      name: "Terms OF Service Screen",
      protected: false
    },
    {
      path: "*",
      component: NotFound,
      name: "404 Not Found",
      protected: false
    }
  ];
  
  export default routes;
  