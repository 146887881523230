import { Box, Typography } from "@mui/material";
import { Logout } from '@mui/icons-material';

import ButtonInput from "../forms/inputs/ButtonInput";

import "../../style/ProfileHeader.css";

interface Props {
  loading: boolean;
  actionLoading: boolean;
  onLogout: () => void;
}

const ProfileHeader = ({ loading, actionLoading, onLogout }: Props) => {
  return (
    <Box className="headline-container">
      <Typography variant="h5">Ihr Profil</Typography>
      <ButtonInput
        actionLoading={actionLoading}
        disabled={loading}
        text="Abmelden"
        endIcon={<Logout />}
        onClick={onLogout}
      />
    </Box>
  );
};

export default ProfileHeader;
