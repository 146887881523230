import {useState} from "react";

import {ProviderData} from "../../hooks/useProviders";
import {VendingMachinesData} from "../../hooks/useVendingMachines";
import {FormOut} from "../../utils/formUtils";
import {GroupsData} from "../../hooks/useGroups";

import ButtonInput from "./inputs/ButtonInput";
import VendingMachinesSelectionInput from "./inputs/VendingMachinesSelectionInput";
import CheckboxGroupInput, {CheckboxGroupSelections} from "./inputs/CheckboxGroupInput";

import "../../style/GenerateFileFormStyle.css";

export interface SelectedVendingData {
  [key: string]: Array<string>;
}

interface GenerateFileFormData {
  selectedVending: SelectedVendingData;
  mergeFiles: boolean;
}

export type GenerateFileFormOut = FormOut<GenerateFileFormData>;

interface Props {
  loading?: boolean;
  actionLoading?: boolean;
  vendingMachines?: VendingMachinesData;
  providers?: ProviderData;
  groups?: GroupsData;
  groupManagement: boolean;
  files: any; // TODO
  onSubmit: (data: GenerateFileFormOut) => void;
}



const GenerateFileForm = ({ loading, actionLoading, vendingMachines={}, providers={}, groups={}, groupManagement, files, onSubmit }: Props) => {

  const maxDailyFiles = Object.keys(vendingMachines || []).length * 10;
  const [checkboxSelections, setCheckboxSelections] = useState<CheckboxGroupSelections>({
    mergeFiles: {
      value: "Zu einem Datenblatt zusammenfassen",
      active: true
    }
  });
  const [selectedVendingMachines, setSelectedVendingMachines] = useState<SelectedVendingData>({});

  const countGeneratedToday = (files: any): number => {
    const startOfCurrentDay = new Date().setUTCHours(0,0,0,0);
    return files.filter((file: any) => {
      return new Date(file.timeCreated).getTime() >= startOfCurrentDay;
    }).length;
  };

  const countSelected = (): number => {
    let counter: number = 0;
    let key: string;
    for (key in selectedVendingMachines) {
      counter += selectedVendingMachines[key].length;
    }
    return counter;
  };
  const checkInput = async (): Promise<void> => {
    const selectedCount = countSelected();
    if(selectedCount === 0) {
      throw new Error("Es wurde kein Automat ausgewählt.");
    }
    else if(countGeneratedToday(files) + selectedCount > maxDailyFiles) {
      throw new Error(
        "Die Maximalanzahl (" + maxDailyFiles + ") an täglichen Generierungen wurde erreicht. Löschen Sie " +
        (countGeneratedToday(files) + selectedCount - maxDailyFiles).toString() +
        " aktuelle Elemente um die Operation durchzuführen."
      );
    }
  };

  const getOutData = (): GenerateFileFormOut => {
    return {
      data: {
        selectedVending: selectedVendingMachines,
        mergeFiles: checkboxSelections.mergeFiles.active
      },
      check: checkInput
    };
  };

  return (
    <>
      <div className="vending-machines-selection-options">
        <CheckboxGroupInput
          loading={loading}
          checkboxSelections={checkboxSelections}
          onChange={(checkboxSelections: CheckboxGroupSelections) => setCheckboxSelections(checkboxSelections)}
        />
      </div>
      <VendingMachinesSelectionInput
        loading={loading}
        providerData={providers}
        vendingMachines={vendingMachines}
        groups={groups}
        groupManagement={groupManagement}
        selectedVendingMachines={selectedVendingMachines}
        onChange={(selectedVendingMachines: SelectedVendingData) => setSelectedVendingMachines(selectedVendingMachines)}
      />
      {
        onSubmit && (
          <ButtonInput
            actionLoading={actionLoading}
            disabled={loading}
            text="Generieren"
            onClick={() => onSubmit(getOutData())}
          />
        )
      } 
    </>
  );
};

export default GenerateFileForm;
