import {ProviderData} from "../../../hooks/useProviders";
import {VendingMachinesData} from "../../../hooks/useVendingMachines";
import {GroupsData} from "../../../hooks/useGroups";

import {SelectedVendingData} from "../GenerateFileForm";
import VendingMachineToggle from "./VendingMachineToggle";

import {Skeleton} from "@mui/material";
import VendingMachinesGroupContainer from "../containers/VendingMachinesGroupContainer";

interface Props {
  loading?: boolean;
  providerData: ProviderData;
  vendingMachines: VendingMachinesData;
  groups: GroupsData;
  groupManagement: boolean;
  selectedVendingMachines: SelectedVendingData;
  onChange: (selectedVendingMachines: SelectedVendingData) => void;
}

const VendingMachinesSelectionInput = ({ loading, providerData, vendingMachines, groups, groupManagement, selectedVendingMachines, onChange }: Props) => {

  if(loading) {
    return (
      <div className="vending-machines-container">
        <Skeleton variant="rectangular" width={200} height={121} />
        <Skeleton variant="rectangular" width={200} height={121} />
      </div>
    );
  }

  const isSelected = (uid: string): boolean => {
    if(!selectedVendingMachines[vendingMachines[uid].providerId]) {
      return false;
    }
    else return selectedVendingMachines[vendingMachines[uid].providerId].indexOf(uid) !== -1;
  };

  const toggleVendingMachine = (uid: string, save: boolean=true, copy?: SelectedVendingData, stayOn: boolean=false): SelectedVendingData | void => {
    if(!copy) {
      copy = Object.assign({}, selectedVendingMachines);
    }
    if (!isSelected(uid)) {
      copy[vendingMachines[uid].providerId] = copy[vendingMachines[uid].providerId] ?
        [uid, ...copy[vendingMachines[uid].providerId]] :
        [uid];
    }
    else if(!stayOn) {
      copy[vendingMachines[uid].providerId] = copy[vendingMachines[uid].providerId].filter((value: string) => {
        return value !== uid;
      });
      if(copy[vendingMachines[uid].providerId].length === 0) {
        delete copy[vendingMachines[uid].providerId];
      }
    }
    if(save) {
      onChange(copy);
    }
    else {
      return copy;
    }
  };


  if(groupManagement) {

    const vendingMachinesInGroup = (groupKey: string): Array<string> => {
      return Object.keys(vendingMachines).filter((key: string) => groupKey === vendingMachines[key].groupId)
    };

    const selectGroup = (groupKey: string): void => {
      let copy: SelectedVendingData = Object.assign({}, selectedVendingMachines);
      const groupSelected = isGroupSelected(groupKey);
      vendingMachinesInGroup(groupKey).map((key: string) => {
        toggleVendingMachine(key, false, copy, !groupSelected);
      });
      onChange(copy);
    };

    const isGroupSelected = (groupKey: string): boolean => {
      return Object.keys(vendingMachines).filter((key: string) => (groupKey === vendingMachines[key].groupId && !isSelected(key))).length === 0
    };

    const used: Array<string> = [];
    return (
      <div className="vending-machines-container">
        {
          Object.keys(groups).map((groupKey: string) => {
            let groupVendingMachines: Array<string> = vendingMachinesInGroup(groupKey);
            if(groupVendingMachines.length > 0) {
              return (
                <VendingMachinesGroupContainer
                  key={groupKey}
                  groupName={groups[groupKey].name}
                  groupSelected={isGroupSelected(groupKey)}
                  onToggle={() => selectGroup(groupKey)}
                >
                  {
                    groupVendingMachines.map((key: string) => {
                      used.push(key);
                      return (
                        <VendingMachineToggle
                          key={key}
                          loading={loading}
                          vendingMachineId={key}
                          isSelected={isSelected(key)}
                          vendingMachineName={vendingMachines[key].name}
                          providerName={providerData[vendingMachines[key].providerId].name}
                          onToggle={() => toggleVendingMachine(key)}
                        />
                      );
                    })
                  }
                </VendingMachinesGroupContainer>
              );
            }
          })
        }
        <VendingMachinesGroupContainer
          groupName="Ohne Gruppe"
          show={used.length < Object.keys(vendingMachines).length}
          groupSelected={isGroupSelected("")}
          onToggle={() => selectGroup("")}
        >
          {
            Object.keys(vendingMachines)
            .filter((key: string) => used.indexOf(key) === -1)
            .map((key: string) => {
              used.push(key);
              return (
                <VendingMachineToggle
                  key={key}
                  loading={loading}
                  vendingMachineId={key}
                  isSelected={isSelected(key)}
                  vendingMachineName={vendingMachines[key].name}
                  providerName={providerData[vendingMachines[key].providerId].name}
                  onToggle={() => toggleVendingMachine(key)}
                />
              );
            })
          }
        </VendingMachinesGroupContainer>
      </div>
    );
  }

  return (
    <div className="vending-machines-container">
      {
        Object.keys(vendingMachines).map(function(key: string) {
          return (
            <VendingMachineToggle
              key={key}
              loading={loading}
              vendingMachineId={key}
              isSelected={isSelected(key)}
              vendingMachineName={vendingMachines[key].name}
              providerName={providerData[vendingMachines[key].providerId].name}
              onToggle={() => toggleVendingMachine(key)}
            />
          );
        })
      }
    </div>
  );
};

export default VendingMachinesSelectionInput;
