import {useDocs} from "./useDatabase";

export interface Provider {
  name: string;
  baseUrl: string;
  active: boolean;
}

export interface ProviderData {
  [key: string]: Provider;
}

export function useProviders(): ProviderData | undefined {
  return useDocs("providers", true) as ProviderData;
}