import "../style/LogoBig.css"; 

interface Props {
  toRow?: boolean;
}

const LogoBig = ({toRow}: Props) => {
  return (
    <div className={"logo-container logo-font " + (toRow ? "logo-container--row" : "")}>
      <div><img className="logo-container__image" src="images/logo.png" /></div>
      <div>
        <div className="logo-container__name">
          Venbob
        </div>
        <p className="logo-container__motto">
          Die schlaue Automaten-Verknüpfung.
        </p>
        </div>
    </div>
  );
};

export default LogoBig;
