import {ChangeEvent} from "react";
import {Skeleton, TextField} from "@mui/material";

interface Props {
  loading?: boolean;
  label: string;
  isRequired?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  defaultValue?: string;
  value?: string;
  onChange?: (value: string) => void;
  type?: string;
  variant?: "standard" | "filled" | "outlined" | undefined;
  width?: number;
  autoComplete?: string;
  helperText?: React.ReactNode;
}

const TextInput = (
  {
    loading,
    isRequired=true,
    disabled=false,
    hidden=false,
    label,
    defaultValue,
    value,
    onChange,
    type="text",
    variant="standard",
    width=200,
    autoComplete,
    helperText
  }: Props) => {

  if(loading) {
    return <div><Skeleton variant="rectangular" width={width} height={48} /></div>;
  }

  return (
    <TextField
      required={isRequired}
      disabled={disabled}
      style={hidden ? {display: "none"} : {}}
      defaultValue={defaultValue}
      value={value}
      label={label}
      variant={variant}
      type={type}
      onChange={(e: ChangeEvent<HTMLInputElement>) => onChange ? onChange(e.target.value) : {}}
      autoComplete={autoComplete}
      helperText={helperText}
    />
  );
};

export default TextInput;
