import {SyntheticEvent} from "react";
import {Checkbox, FormControlLabel, FormGroup} from "@mui/material";

export interface CheckboxGroupSelections {
  [key: string]: CheckboxSelection;
}

export interface CheckboxSelection {
  value: string;
  active: boolean;
}

interface Props {
  loading?: boolean;
  checkboxSelections: CheckboxGroupSelections;
  onChange: (checkboxSelections: CheckboxGroupSelections) => void;
}

const CheckboxGroupInput = ({ loading, checkboxSelections, onChange }: Props) => {

  const toggleSelection = (key: string): void => {
    let copy = JSON.parse(JSON.stringify(checkboxSelections));
    copy[key].active = !copy[key].active;
    onChange(copy);
  };

  return (
    <FormGroup>
      {
        Object.keys(checkboxSelections).map((key: string) => {
          return (
            <FormControlLabel
              key={key}
              control={<Checkbox checked={checkboxSelections[key].active} disabled={loading} />}
              label={checkboxSelections[key].value}
              onChange={(e: SyntheticEvent) => toggleSelection(key)}
            />
          );
        })
      }
    </FormGroup>
  );
};

export default CheckboxGroupInput;
