import {Box} from "@mui/material";

interface Props {
  children: React.ReactNode;
  label?: string;
  isRequired?: boolean;
  createForm?: boolean;
}

const FormRow = ({ children, label, isRequired, createForm=false }: Props) => {
  return (
    <Box className="field-row" component={createForm ? "form" : "div"} autoComplete="on">
      {label && <div className="field-row__label">{ label }{ isRequired && "*" }</div>}
      { children }
    </Box>
  );
};

export default FormRow;
