import {Checkbox, Paper, Skeleton} from "@mui/material";

interface Props {
 loading?: boolean;
  vendingMachineId: string;
  isSelected: boolean;
  vendingMachineName: string;
  providerName: string;
  onToggle: () => void;
}

const VendingMachineToggle = ({ loading, vendingMachineId, isSelected, vendingMachineName, providerName, onToggle }: Props) => {

  if(loading) {
    return <div><Skeleton variant="rectangular" width={200} height={121} /></div>;
  }

  return (
    <Paper
      elevation={isSelected ? 20 : 2}
      className={"vending-machines-container__vending-machine"}
      onClick={() => onToggle()}
    >
      <div className="vending-machines-container__header">
        <span className="medium-text">Automat</span>
        {
          <Checkbox style={{padding: "0"}} checked={isSelected} />
        }
      </div>
      <div className="big-text">{vendingMachineName}</div>
      <div className="medium-text">
        Anbieter: {providerName} <br/>
        ID: {vendingMachineId}
      </div>
    </Paper>
  );
};

export default VendingMachineToggle;
