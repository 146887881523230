import { useState } from "react";
import { Link, NavigateFunction, useNavigate } from "react-router-dom";

import { auth } from "../config/firebase";

import { createUserWithEmailAndPassword } from "firebase/auth";

import { createInfo, FormInfo } from "../utils/formUtils";

import SplitScreen from "../components/SplitScreen";
import LogoBig from "../components/LogoBig";
import AuthFormContainer from "../components/forms/containers/AuthFormContainer";
import RegisterForm, { RegisterFormOut } from "../components/forms/RegisterForm";
import Footer from "../components/Footer";

interface Props {}

const Register = ({}: Props) => {

  const navigate: NavigateFunction = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);

  const [formInfo, setFormInfo] = useState<FormInfo>(createInfo());

  const register = async (check: () => Promise<void>, email: string, password: string) => {
    setLoading(true);
    try {
      await check();
      await createUserWithEmailAndPassword(auth, email, password);
      setFormInfo(createInfo(true, "Registrierung erfolgreich abgeschlossen."));
      navigate("/login");
    }
    catch (error: any) {
      setFormInfo(createInfo(false, error.message));
      setLoading(false);
    }
  };

  return (
    <>
    <SplitScreen>
      <LogoBig />
      <AuthFormContainer
        headline="Registrieren"
        info={formInfo}
        footer={
          <>
            Schon einen Account? Melden Sie sich <Link to="/login" className="href-link">hier</Link> an.
          </>
        }
      >
        <RegisterForm
          onSubmit={(data: RegisterFormOut) => register(data.check, data.data.email, data.data.password1)}
          actionLoading={loading}
        />
      </AuthFormContainer>
    </SplitScreen>
    <Footer isOnLogin={true} />
    </>
  );
};

export default Register;
