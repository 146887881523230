import {auth, db} from "../../config/firebase";

import {useState} from "react";

import {FormOut} from "../../utils/formUtils";
import {Group, GroupsData} from "../../hooks/useGroups";

import FormRow from "./containers/FormRow";
import ButtonInput from "./inputs/ButtonInput";
import FormSection from "./containers/FormSection";
import TextInput from "./inputs/TextInput";
import {newDoc, setUserMultiple} from "../../utils/userUtils";

import {
  Alert,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent, Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import {setDeepValue} from "../../utils/dbUtils";
import {VendingMachinesData} from "../../hooks/useVendingMachines";
import {deleteDoc, doc} from "firebase/firestore";
import {Delete, Print} from "@mui/icons-material";

export type NewGroupFormOut = FormOut<Group>;

export type GroupsFormOut = FormOut<GroupsData>;

interface Props {
  actionLoading?: boolean;
  activated: boolean;
  groups?: GroupsData;
  vendingMachines?: VendingMachinesData;
  onSubmit: (data: NewGroupFormOut | GroupsFormOut) => void;
}

const GroupsForm = ({actionLoading, activated, groups={}, vendingMachines={}, onSubmit }: Props) => {

  const [groupsData, setGroupsData] = useState<GroupsData>(groups);

  const [newGroupName, setNewGroupName] = useState<string>("");

  const setValue = (key: string, value: string, deepKey?: string) => {
    setGroupsData(setDeepValue(groupsData, key, value, deepKey));
  };

  const checkNewGroup = async (): Promise<void> => {
    if(!newGroupName) {
      throw new Error("Nicht alle erforderlichen Felder wurden ausgefüllt.");
    }
  };

  const checkGroups = async (): Promise<void> => {
    let key: string;
    for(key in groupsData) {
      if(!groupsData[key].name) {
        throw new Error("Nicht alle erforderlichen Felder wurden ausgefüllt.");
      }
    }
  };

  const out = {
    name: newGroupName
  };

  const saveNewGroup = async (): Promise<void> => {
    await newDoc("users/" + auth.currentUser?.uid + "/groups", out);
    setNewGroupName("");
  };

  const deleteGroup = async (key: string): Promise<void> => {
    await deleteDoc(doc(db, "users/" + auth.currentUser?.uid + "/groups/" + key));
  };

  const updateGroups = async (): Promise<void> => {
    await setUserMultiple(groupsData, "groups");
  };

  const getNewGroupOutData = (): NewGroupFormOut => {
    return {
      data: out,
      check: checkNewGroup,
      save: saveNewGroup
    };
  };

  const updateGroupOutData = (): GroupsFormOut => {
    return {
      data: {},
      check: checkGroups,
      save: updateGroups
    };
  };

  const deleteGroupOutData = (key: string): GroupsFormOut => {
    return {
      data: {},
      check: async () => {},
      save: async () => deleteGroup(key)
    };
  };

  return (
    <>
      <div className="feedback-container" style={{display: activated ? "none" : ""}}>
        <Alert severity="info" className="feedback-container__info">Gruppenverwaltung ist deaktiviert. Aktivieren Sie die Funktion in den Einstellungen.</Alert>
      </div>
      <div className={activated ? "" : "unavailable"}>
        <FormSection headline="Gruppe erstellen">
          <FormRow label="Gruppenname">
            <TextInput
              disabled={actionLoading || !activated}
              label="Name"
              value={newGroupName}
              onChange={(value: string) => setNewGroupName(value)}
            />
          </FormRow>
          <ButtonInput
            disabled={!activated}
            actionLoading={actionLoading}
            text="Hinzufügen"
            onClick={() => onSubmit(getNewGroupOutData())}
          />
        </FormSection>
        <FormSection headline="Gruppenzuordnung" text="Automaten können unter der Automatenübersicht Gruppen zugewiesen werden.">

          <TableContainer>
            <Table className="form-table form-table--groups">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Zugewiesene Automaten</TableCell>
                  <TableCell>Optionen</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  Object.keys(groups).map((groupKey: string, index: number) => {
                    let foundOne = false;
                    return (
                      <TableRow key={groupKey}>
                        <TableCell>
                          <TextInput
                            disabled={!activated}
                            label="Name"
                            defaultValue={groups[groupKey].name}
                            onChange={(value: string) => setValue(groupKey, value, "name")}
                          />
                        </TableCell>
                        <TableCell>
                          {
                            Object.keys(vendingMachines)
                            .filter((key: string) => groupKey === vendingMachines[key].groupId)
                            .map((vendingMachineKey: string, index: number) => {
                              const returnVal = (foundOne ? ", " : "") + vendingMachines[vendingMachineKey].name;
                              foundOne = true;
                              return returnVal;
                            })
                          }
                        </TableCell>
                        <TableCell>
                          <ButtonInput
                            disabled={!activated}
                            text={<Delete />}
                            endIcon={null}
                            onClick={() => onSubmit(deleteGroupOutData(groupKey))}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })
                }
              </TableBody>
            </Table>
          </TableContainer>

          <ButtonInput
            disabled={!activated}
            actionLoading={actionLoading}
            text="Speichern"
            onClick={() => onSubmit(updateGroupOutData())}
          />
        </FormSection>
      </div>
    </>
  );
};

export default GroupsForm;
