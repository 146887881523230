import React from "react";

import "../style/SplitScreen.css"; 

interface Props {
  children: React.ReactNode;
}

const SplitScreen = ({ children }: Props) => {
  return (
    <div 
      className="split-screen-container"
    >
      {children}
    </div>
  );
};

export default SplitScreen;
