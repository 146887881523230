import { useState } from "react";

import {FormOut, checkEmailInput, checkPasswordInput} from "../../utils/formUtils";

import { Checkbox, FormControlLabel } from "@mui/material";

import TextInput from "./inputs/TextInput";
import ButtonInput from "./inputs/ButtonInput";

import "../../style/AuthForm.css";

interface RegisterFormData {
  email: string;
  password1: string;
  password2: string;
  guidelinesAccepted: boolean;
}

export type RegisterFormOut = FormOut<RegisterFormData>;

interface Props {
  actionLoading?: boolean;
  onSubmit: (data: RegisterFormOut) => void;
}

const RegisterForm = ({ actionLoading, onSubmit }: Props) => {

  const [email, setEmail] = useState<string>("");
  const [password1, setPassword1] = useState<string>("");
  const [password2, setPassword2] = useState<string>("");
  const [guidelinesAccepted, setGuidelinesAccepted] = useState<boolean>(false);

  const checkInput = async (): Promise<void> => {
    throw new Error("Registrierungen sind derzeit leider nicht möglich."); // remove if registration should be active
    try {
      checkPasswordInput(password1, password2);
      if(!email) {
        throw new Error("Nicht alle erforderlichen Felder wurden ausgefüllt.");
      }
      else if(!checkEmailInput(email)) {
        throw new Error("Die eingegebene Email-Addresse ist ungültig.");
      }
      else if(!guidelinesAccepted) {
        throw new Error("Die erforderlichen Richtlinien wurden nicht akzeptiert.");
      }
    }
    catch (error: any) {
      throw error;
    }
  };

  const getOutData = (): RegisterFormOut => {
    return {
      data: {
        email,
        password1,
        password2,
        guidelinesAccepted
      },
      check: checkInput
    };
  };
  
  return (
    <>
      <TextInput
        label="Email"
        type="email"
        onChange={(value: string) => setEmail(value)}
      />
      <TextInput
        label="Passwort"
        type="password"
        onChange={(value: string) => setPassword1(value)}
      />
      <TextInput
        label="Passwort wiederholen"
        type="password"
        onChange={(value: string) => setPassword2(value)}
      />
      <FormControlLabel
        control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 16 }, padding: "0 9px 20px 0" }} />}
        label={<span className="small-text">Hiermit akzeptiere ich die Nutzungsbedingungen und die Datenschutzerklärung.</span>}
        onChange={(e: any) => setGuidelinesAccepted(e.target.checked)}
      />
      <ButtonInput
        actionLoading={actionLoading}
        disabled={!email || !password1 || !password2 || !guidelinesAccepted}
        text="Registrieren"
        endIcon={" "}
        onClick={() => onSubmit(getOutData())}
        isSubmit={true}
        variant="outlined"
        params={{className: "loginLoadingButton"}}
      />
    </> 
  );
};

export default RegisterForm;
