import React from "react";
import { Link } from "react-router-dom";

import "../style/Footer.css";

interface Props {
  isOnLogin?: boolean;
}

const Footer = ({isOnLogin}: Props) => {

  if(isOnLogin) {
    return (
      <div className="footer-container--login">
        <p>
          Sie kennen Venbob noch nicht? <Link className="href-link" to="/kickoff">Erfahren Sie hier mehr</Link>.
        </p>
        Bei Interesse, Fragen oder anderen Anliegen bitte melden. <br/>
        Email: office@venbob.com | Tel.: +43 650 4040 537
      </div>
    );
  }

  return (
    <footer className="footer-container">
      <div>
        <div className="big-text">Weiter Seiten</div>
        <div>
          <Link to="/kickoff" className="hidden-href-link">
            Kick-Off
          </Link>
        </div>
        <div>
          <Link to="/imprint" className="hidden-href-link">
            Impressum
          </Link>
        </div>
        <div>
          <Link to="/privacy" className="hidden-href-link">
            Datenschutz
          </Link>
        </div>
        <div>
          <Link to="/termsofservice" className="hidden-href-link">
            Nutzungsbedingungen
          </Link>
        </div>
      </div>
      <div>
        <div className="big-text">Kontakt</div>
        <div><span>Tel.:</span> +43 650 4040 537</div>
        <div><span>Email:</span> office@venbob.com</div>
      </div>
      {/*<div>
        <div className="big-text">Offizieller Partner von</div>
        <div>
          <img className="footer-container__image" src="images/providers/easyVending.png" />
        </div>
      </div>*/}
    </footer>
  );
};

export default Footer;
