import { useState } from "react";
import { auth } from "../config/firebase";

import { addUser } from "../utils/userUtils";
import { createInfo, FormInfo } from "../utils/formUtils";
import {ProviderData, useProviders} from "../hooks/useProviders";
import {VendingMachinesData} from "../hooks/useVendingMachines";

import { Box, Typography } from "@mui/material";

import MainContainer from "../components/MainContainer";
import StepperNavigation from "../components/StepperNavigation";
import FormContainer from "../components/forms/containers/FormContainer";
import ResetPasswordForm, { ResetPasswordFormOut } from "../components/forms/ResetPasswordForm";
import PersonalInformationForm, { PersonalInformationFormOut } from "../components/forms/PersonalInformationForm"
import ProviderForm, {ProviderCheckInputOut, ProviderFormOut} from "../components/forms/ProviderForm";
import StepperNavigationButtons from "../components/forms/inputs/StepperNavigationButtons";

import "../style/InitLogin.css";
import {User} from "firebase/auth";

interface Props {
  pageLoading: boolean;
  firebaseUser: User | null;
}

const steps: Array<string> = ["Passwort festlegen", "Persönliche Daten angeben", "Verknüpfungen erstellen"];

const Welcome = ({ pageLoading, firebaseUser }: Props) => {

  const [activeStep, setActiveStep] = useState<number>(0);

  const [actionLoading, setActionLoading] = useState<boolean>(false);

  const [formInfo, setFormInfo] = useState<FormInfo>(createInfo());

  const providerData: ProviderData | undefined = useProviders();

  const [passwordForm, setPasswordForm] = useState<ResetPasswordFormOut>();
  const [personalInformationForm, setPersonalInformationForm] = useState<PersonalInformationFormOut>();
  const [providerForm, setProviderForm] = useState<ProviderFormOut>();

  const isLoading: boolean = !providerData || pageLoading;

  const checkInputs = async (): Promise<ProviderCheckInputOut | undefined> => {
    try {
      await passwordForm?.check();
      if(activeStep >= 1) {
        await personalInformationForm?.check();
        if(activeStep >= 2) {
          return await providerForm?.check();
        }
      }
      return;
    }
    catch (error: any) {
      throw error;
    }
  };

  const handleNext = async () => {
    setFormInfo(createInfo());
    setActionLoading(true);

    try {
      const lastStepData: ProviderCheckInputOut | undefined = await checkInputs();
      if(lastStepData) {
        if(firebaseUser) {
          await addUser(
            firebaseUser,
            passwordForm!.data.password1,
            personalInformationForm!.data,
            providerForm!.data,
            lastStepData.vendingMachines
          );
        }
      }
      else {
        setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
        setActionLoading(false);
      }
    }
    catch (error: any) {
      setFormInfo(createInfo(false, error.message));
      setActionLoading(false);
    }
  };

  const handleBack = () => {
    setFormInfo(createInfo());
    setActiveStep((prevActiveStep: number) => prevActiveStep - 1);
  };

  return (
    <MainContainer>
      <Box className="init-login-container">
        <Typography variant="h5">Erstkonfiguration</Typography>

        <StepperNavigation
            steps={steps}
            activeStep={activeStep}
        />

        <div>
          <FormContainer 
            headline={steps[0]}
            description="Da Sie sich das erste mal angemeldet haben müssen Sie nun Ihr Passwort ändern."
            info={formInfo}
            show={activeStep === 0}
          >
            <ResetPasswordForm
              loading={isLoading}
              onChange={(data: ResetPasswordFormOut) => setPasswordForm(data)}
            />
          </FormContainer>
          {
            !isLoading && (
              <>
                <FormContainer
                  headline={steps[1]}
                  description="Um Sie auch in Zukunft zuordnen und kontaktieren zu können, benötigen wir Ihre grundlegenden Identitäts- und Kontaktdaten."
                  info={formInfo}
                  show={activeStep === 1}
                >
                  <PersonalInformationForm
                    email={auth.currentUser?.email}
                    defaultData={null}
                    onChange={(data: PersonalInformationFormOut) => setPersonalInformationForm(data)}
                  />
                </FormContainer>

                <FormContainer
                  headline={steps[2]}
                  description="Um die notwendigen Daten für die Weiterverarbeitung abzurufen, benötigen wir Ihre Anmeldedaten der gewünschten Anbieter."
                  info={formInfo}
                  show={activeStep === 2}
                  createForm={false}
                >
                  <ProviderForm
                    providers={providerData}
                    onChange={(data: ProviderFormOut) => setProviderForm(data)}
                  />
                </FormContainer>
              </>
            )
          }
        </div>
        <StepperNavigationButtons
            loading={isLoading}
            actionLoading={actionLoading}
            steps={steps}
            activeStep={activeStep}
            handleBack={() => handleBack()}
            handleNext={() => handleNext()}
        />
      </Box>
    </MainContainer>
  );
};

export default Welcome;
